<template>
  <div style="width: 100vw;height: 100vh;font-size: 6.7vmin;text-align: center;padding-top: 30vh">
    {{alert1}}<br>{{alert2}}
  </div>
</template>

<script>
export default {
  data(){
    return {
      alert1:"",
      alert2:""
    }
  },
  mounted() {
    var action = this.$route.query.action;
    if (action == "confirm") {
      this.alert1 = "订阅警情成功，"
      this.alert2 = "出现警情将会第一时间通知您。"
    }else {
      this.alert1 = "未订阅警情，"
      this.alert2 = "请返回并点击“确认接收”"
    }
  }
}
</script>

<style scoped>

</style>